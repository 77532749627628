import React, { Fragment } from "react";
import {  MDBRow, MDBCol, MDBInput, MDBCard, MDBCardBody, ToastContainer, toast } from "mdbreact";
import axios from "axios";
import AdSense from 'react-adsense';
import CreatorBlock from '../components/CreatorBlock';
import {Link} from 'react-router-dom';
import "./AllMaps.css";

const apiPath = "creator/all";


class AllMaps extends React.Component {
  constructor(propsN) {
      super(propsN);
      this.state = {
          isLoading: true,
          creator: "",
          code: "",
          name: "",
          gamemode: "",
          postdate: "",
          hasMedia: "",
          perPage: 32,
          lastItem: ""

      }
  };

  componentDidMount() {
    const options = { 
        'queryStringParameters': {
            'perPage': this.state.perPage,
            'isVerified': true
        }
    };

    this.getCreators(options);
    }


  async getCreators(options) {
      try {
        const mapsRes = await axios.get("https://icapi.cdn.eu.com/api/" + apiPath, {
          params: options.queryStringParameters,
          crossDomain: true
      });
        const maps = mapsRes.data.Items
        this.shuffleArray(maps);
        this.setState({ maps });
        if (mapsRes.data.LastEvaluatedKey) {
            this.setState({lastItem: mapsRes.data.LastEvaluatedKey.username});
        } 
    } catch (e) {
      console.log(e);
      toast.warning('Error fetching creators, please try again', {
          position: "top-right",
        });
    }

    this.setState({ isLoading: false });
  }

  handlesearch = async () => {

    const options = { 
        'queryStringParameters': {
            'creator': this.state.creator.toLowerCase().replace(" ", "-")
        }
    };

    try {
        const mapsRes = await axios.get("https://icapi.cdn.eu.com/api/" + apiPath, {
          params: options.queryStringParameters,
          crossDomain: true
      });
        const maps = mapsRes.data.Items;
        this.setState({ maps });
        if (mapsRes.data.LastEvaluatedKey) {
            this.setState({lastItem: mapsRes.data.LastEvaluatedKey.username});
        } 
    } catch (e) {
      console.log(e);
      toast.warning('Error fetching creators, please try again', {
          position: "top-right",
        });
    }
  }

  nextPage = async() => {
    this.setState({isLoading: true});
    const options = {
        'queryStringParameters': {
            'lastItem': this.state.lastItem,
            'perPage': this.state.perPage,
            'isVerified': true
        }
    };
    
      try {
        const mapsRes = await axios.get("https://icapi.cdn.eu.com/api/" + apiPath, {
          params: options.queryStringParameters,
          crossDomain: true
      });
        const maps = mapsRes.data.Items;
        this.shuffleArray(maps);
        this.setState({ maps });
        if (mapsRes.data.LastEvaluatedKey) {
            this.setState({lastItem: mapsRes.data.LastEvaluatedKey.username});
        } 
        setTimeout(() => {
          document.getElementById("allMapsArea").scrollIntoView({block: "start"});
          window.scrollBy(0, -60);
          }, 10)
    } catch (e) {
      console.log(e);
      toast.warning('Error fetching creators, please try again', {
          position: "top-right",
        });
    }

    this.setState({ isLoading: false });
}

  handleChangeFilter = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  renderMapsList(maps) {
    let mapCols = maps.map(map => {
        return (
            <MDBCol size="6" lg="4" xl="3" className="my-2 my-lg-4 px-1 px-md-2 px-lg-3" key={map.username}>
              <CreatorBlock name={map.username} description={map.description} image={map.image} social={map.social} />
            </MDBCol>
        );})
      // mapCols.splice(9, 0, this.renderAdd());
      return mapCols;
  }

  renderAdd() {
    return(
      <MDBCol size="6" lg="4" xl="3" className="my-2 my-lg-4 px-0 px-md-2 px-lg-3" key="add">
            <MDBCard>
                <MDBCardBody>
                <AdSense.Google
                        client='ca-pub-9509107377424765'
                        slot='1713708949'
                        style={{ display: 'block' }}
                        format='auto'
                    responsive='true'
                        />
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    )
}


renderLoader() {
    return (
      <Fragment>
      <div className="w-100 text-center text-white my-5">
        <div className="spinner-grow" role="status">
            <span className="sr-only">Loading Creators...</span>
          </div>
          <h1>Loading Creators</h1>
      </div>
      </Fragment>
    )
  }

  shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
  }

  render() {
  return (
    <Fragment>
        <MDBRow>
          <MDBCol className="headingBlue text-center firstBlock">
           <h1 className="display-4 display-1-md text-uppercase white-text featuredHeading">All Creators</h1>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="searchBlock">
          <MDBRow className="mx-0">
                      <div className="mx-auto my-4 p-4 innerSearchBlock">

                            <MDBRow>
                              <MDBCol size="12" sm="6" md="8">
                                <MDBInput label="SEARCH FOR CREATOR" size="lg" id="creator" value={this.state.creator} onChange={this.handleChangeFilter} />
                              </MDBCol>
                              <MDBCol size="12" sm="6" md="4">
                                <div className="fortnite-btn w-100 mt-3" onClick={this.handlesearch}>
                                  <span className="text-uppercase">Search</span>
                              </div>
                              </MDBCol>
                            </MDBRow>
                      </div>   
                  </MDBRow>  
          </MDBCol>
        </MDBRow>

        <MDBRow className="allMapsArea" id="allMapsArea">
          <MDBCol className="my-5 px-1">
          <MDBRow className="mx-0 w-100" style={{overflowX: "hidden"}}>

          {!this.state.isLoading ? this.renderMapsList(this.state.maps) : this.renderLoader()}
          </MDBRow>
          <MDBRow className="mx-0 w-100">
                            <MDBCol size="6">
                                <div className="fortnite-btn w-100" onClick={() => {this.getCreators({'queryStringParameters': {'perPage': this.state.perPage,'isVerified': true}})}}>
                                    <span className="text-uppercase">{this.state.isLoading ? "Loading..." : "First"}</span>
                                </div>
                               
                            </MDBCol>
                            {this.state.lastItem && 
                            <MDBCol size="6">
                                
                                    <div className="fortnite-btn w-100" onClick={this.nextPage}>
                                    <span className="text-uppercase">{this.state.isLoading ? "Loading..." : "Next"}</span>
                                </div>
                                
                               
                            </MDBCol>
                            }
                        </MDBRow>
                        
                        <div className="mx-auto my-3" style={{width: "100%", maxWidth: "1200px"}}>
                        <AdSense.Google
                        client='ca-pub-9509107377424765'
                        slot='1713708949'
                        style={{ display: 'block' }}
                        format='auto'
                        responsive='true'
                        />
                        </div>
                        
                       

          </MDBCol>
        </MDBRow>
        <MDBRow className="goldenBG">
                    <MDBCol className="px-0">
                        <MDBRow className="mx-auto p-3 p-sm-5">
                            <MDBCol>
                                <Link to="/howToUseCode">
                                    <div className="fortnite-btn">
                                        <span className="text-uppercase">how to use a code</span>
                                    </div>
                                </Link>
                            </MDBCol>
                            <MDBCol>
                                <Link to="/howToCreateCode">
                                    <div className="fortnite-btn">
                                        <span className="text-uppercase">how to create a code</span>
                                    </div>
                                </Link>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
        <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
                />
    </Fragment>
  )
  }
};

export default AllMaps;