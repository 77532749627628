import React from "react";
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';



class ErrorPage extends React.Component {
    render() {

        return (
            <div className="h-100 creatorMapsArea">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol md="8" className="mx-auto text-center white-text" style={{marginTop: "15%"}}>
                        <h1>THESE ARE NOT THE LLAMAS YOU'RE LOOKING FOR</h1>
                        <br />
                        <img src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/media/img/sys/404.png" className="max-auto my-4" style={{maxWidth: "90%"}} alt="Not Found" />
                        <button className="fortnite-btn border-0 my-4" onClick={() => {window.location = "https://island-codes.com/"}}>
                            <span className="text-uppercase">Back to Home</span>
                        </button>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                    </div>
    
            
          );
    }
   
};

export default ErrorPage;