import { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-135447714-1');

class ScrollToTop extends Component {
    

    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        window.scrollTop = 0;
        document.getElementById("mainContainer").scrollIntoView({block: "start"})

        ReactGA.pageview(window.location.pathname + window.location.search);
        
      }
    }
  
    render() {
      return this.props.children;
    }
  }
  
  export default withRouter(ScrollToTop);