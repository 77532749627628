import React, { Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { 
    Navbar, 
    NavbarBrand, 
    NavbarNav, 
    NavItem, 
    NavLink, 
    NavbarToggler, 
    Collapse,  
    MDBIcon,
    MDBNavItem,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu
  
  } from "mdbreact";

    import "./Navigation.css";

    class Navigation extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                collapse: false
            };
            this.onClick = this.onClick.bind(this);
        }
      
        onClick() {
          this.setState({
              collapse: !this.state.collapse
            });
        }
      
        render() {
          const styleNav = {backgroundColor: '#2a2a2a', fontSize: "1.7rem"};
          const styleNavBrand = {fontSize: "2rem"};
          const navItemStyle = {maxWidth: "700px"};
          const navIconStyle = {maxWidth: "260px", float: "right", flexDirection: "row"};
          return(
            <div>
              <Router onUpdate={() => window.scrollTo(0, 0)}>
              <Fragment>
              <header id="mainHeader">
                  <Navbar style={styleNav} dark expand="md" scrolling fixed="top">
                    <NavbarBrand className="text-uppercase main-logo" style={styleNavBrand}>
                        <strong id="logoText">Fortnite Creative</strong>
                        <img src="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/logoMobile.png" id="logoMobile" height="45" alt="Fortnite Creative Codes" />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.onClick} />
                    <Collapse isOpen = { this.state.collapse } navbar className=" text-right">
                      <NavbarNav className="mx-auto" style={navItemStyle}>
                        <NavItem>
                            <NavLink to="/" onClick={this.onClick} className="text-uppercase">Home</NavLink>
                         </NavItem>
                        <NavItem>
                        <NavLink to="/maps" onClick={this.onClick} className="text-uppercase">All Maps</NavLink>
                        </NavItem>
                        <NavItem className="logo">
                        <img src="https://cdn.eu.com/c/islandcodes/img/logo.png" height="50" alt="Island-Codes.com Logo" />
                        </NavItem>
                        <NavItem>
                            <NavLink to="/featured" onClick={this.onClick} className="text-uppercase">Featured Maps</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/creators" onClick={this.onClick} className="text-uppercase">Creators</NavLink>
                        </NavItem>
                      </NavbarNav>
                      <NavbarNav style={navIconStyle}>
                        <NavItem>
                        <NavLink to="//discord.gg/yyWnudA" onClick={() => { window.open("https://discord.gg/yyWnudA", "_blank")}}><MDBIcon fab icon="discord" /></NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink to="//www.instagram.com/fortnite_islandcodes/" onClick={() => { window.open("https://www.instagram.com/fortnite_islandcodes/", "_blank")}}><MDBIcon fab icon="instagram" /></NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink to="//twitter.com/island_codes" onClick={() => { window.open("https://twitter.com/island_codes", "_blank")}}><MDBIcon fab icon="twitter" /></NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink to="//www.youtube.com/channel/UCFdH7BM7PzYaxhS2TacIZ-g" onClick={() => { window.open("https://www.youtube.com/channel/UCFdH7BM7PzYaxhS2TacIZ-g", "_blank")}}><MDBIcon fab icon="youtube" /></NavLink>
                        </NavItem>
                        


                      
                         <MDBNavItem>
                          <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                              <MDBIcon className="yellow-text" icon="user" />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="dropdown-warning" right>
                            <a class="p-0" href="https://creator.island.codes" target="_blank" rel="noopener noreferrer"><button type="button" tabindex="0" class="dropdown-item text-uppercase">Creator Area</button></a>
                            <a class="p-0" href="https://creator.island.codes" target="_blank" rel="noopener noreferrer"><button type="button" disabled tabindex="0" class="dropdown-item text-uppercase">Login</button></a>
                            <a class="p-0" href="https://creator.island.codes" target="_blank" rel="noopener noreferrer"><button type="button" disabled tabindex="0" class="dropdown-item text-uppercase">Register</button></a>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </MDBNavItem>

                      </NavbarNav>
                    </Collapse>
                  </Navbar>
                </header>
              
              <div id="mainContainer">
                        {this.props.children}

              </div>
              </Fragment>
                
              </Router>
              
            </div>
          );
        }
      }

export default Navigation;