import React, {Fragment} from "react";
import {MDBRow, MDBCol} from "mdbreact";
import {Link} from 'react-router-dom';


class AllMaps extends React.Component {

    render() {
        const styleVerCenter = {    
            position: "absolute",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)"}
        return (
            <Fragment>
                <MDBRow>
                    <MDBCol className="text-center firstBlock searchBlock">
                        <h1 className="display-4 display-1-md text-uppercase white-text featuredHeading">How to use a code</h1>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="allMapsArea">
                       <MDBRow>
                           <MDBCol size="5" className="text-center">
                           <div className="h-100">
                           <div style={styleVerCenter}>
                           <h1 className="align-middle white-text">STEP 1</h1>
                           <h2 className="mapBlockName">START CREATIVE SERVER</h2>
                           </div>
                           </div>
                           </MDBCol>
                           <MDBCol size="7" className="p-5">
                               <img src="https://cdn.eu.com/c/islandcodes/img/howToCode1.jpg" style={{width: "100%"}} alt="Fortnite how to use a code - Start Creative Server" />
                           </MDBCol>
                       </MDBRow>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="searchBlock">
                    <MDBCol>
                    <MDBRow>
                           <MDBCol size="5" className="text-center">
                           <div className="h-100">
                           <div style={styleVerCenter}>
                           <h1 className="align-middle white-text">STEP 2</h1>
                           <h2 className="mapBlockName">FIND THE FEATURED ISLAND RIFT</h2>
                           </div>
                           </div>
                           </MDBCol>
                           <MDBCol size="7" className="p-5">
                               <img src="https://cdn.eu.com/c/islandcodes/img/howToCode2.jpg" style={{width: "100%"}} alt="Fortnite how to use a code - Find Featured Rift" />
                           </MDBCol>
                       </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="allMapsArea">
                       <MDBRow>
                           <MDBCol size="5" className="text-center">
                           <div className="h-100">
                           <div style={styleVerCenter}>
                           <h1 className="align-middle white-text">STEP 3</h1>
                           <h2 className="mapBlockName">ENTER THE ISLAND CODE</h2>
                           </div>
                           </div>
                           </MDBCol>
                           <MDBCol size="7" className="p-5">
                               <img src="https://cdn.eu.com/c/islandcodes/img/howToCode3.jpg" style={{width: "100%"}} alt="Fortnite how to use a code - Enter The Island" />
                           </MDBCol>
                       </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="goldenBG">
                    <MDBCol className="px-0">
                        <MDBRow className="mx-auto p-3 p-sm-5">
                            <MDBCol>
                                <Link to="/howToUseCode">
                                    <div className="fortnite-btn">
                                        <span className="text-uppercase">how to use a code</span>
                                    </div>
                                </Link>
                            </MDBCol>
                            <MDBCol>
                                <Link to="/howToCreateCode">
                                    <div className="fortnite-btn">
                                        <span className="text-uppercase">how to create a code</span>
                                    </div>
                                </Link>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                
            </Fragment>
        )
    }
};

export default AllMaps;