import 'react-app-polyfill/ie9';
import React, {Fragment} from "react";
import {MDBRow, MDBCol, MDBCard, MDBCardBody, ToastContainer, toast} from "mdbreact";
import MapBlock from '../components/MapBlock';
import Carousel from '../components/Carousel';
import "./Featured.css";
import {Link} from 'react-router-dom';


class AllMaps extends React.Component {
    constructor(propsN) {
        super(propsN);
        this.state = {
            isLoading: true
        }
    };
    async componentDidMount() {
        
        try {          
            const api_call = await fetch(`https://island-codes.com/media/featured/featuredMaps.json`);
            const maps = await api_call.json();
            const api_call2 = await fetch(`https://island-codes.com/media/featured/theBlock.json`);
            const theBlock = await api_call2.json();
            this.setState({maps, theBlock});
        } catch (e) {
            console.log(e);
            toast.warning('Error fetching map, please try again', {
                position: "top-right",
              });
        }

        this.setState({isLoading: false});
        setTimeout(() => {
            window.scrollTop = 100;
        document.getElementById("mainContainer").scrollIntoView({block: "start"});
        window.scrollBy(0, -100);
        }, 10)
        
    }

    renderMapsList(maps) {
        this.shuffleArray(maps);
        const tableCells = maps.map(map => {
            return (
                <MDBCol md="6" xl="4" className="my-2 my-sm-3 my-md-4" key={map.code}>
                    <MapBlock
                        history={this.props.history}
                        name={map.name}
                        creator={map.creator}
                        code={map.code}
                        gameMode={map.gameModes}
                        img={map.images && map.images.main}/>
                </MDBCol>

            );
        })
        return tableCells;
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    renderFeaturedCards(maps) {
        this.shuffleArray(maps);
        return maps.map((map, index) => 
            <MDBCard className={"mx-sm-2 mx-xl-5 h-100" + (index % 2 ? " featuredCard1" : " featuredCard2")}>
                <MDBCardBody>
                <picture>
                    <img
                    
                    sizes="(max-width: 1920px) 100vw, 1920px"
                    srcSet={map.images && map.images.main ?
                        
                    `https://cdn.eu.com/c/islandcodes/media/img/320/`+ map.images.main +`.jpg 320w,
                    https://cdn.eu.com/c/islandcodes/media/img/640/`+ map.images.main +`.jpg 640w,
                    https://cdn.eu.com/c/islandcodes/media/img/768/`+ map.images.main +`.jpg 768w,
                    https://cdn.eu.com/c/islandcodes/media/img/1024/`+ map.images.main +`.jpg 1024w,
                    https://cdn.eu.com/c/islandcodes/media/img/1366/`+ map.images.main +`.jpg 1366w,
                    https://cdn.eu.com/c/islandcodes/media/img/1600/`+ map.images.main +`.jpg 1600w,
                    https://cdn.eu.com/c/islandcodes/media/img/1920/`+ map.images.main +`.jpg 1920w`
                    :
                    `https://cdn.eu.com/c/islandcodes/media/img/320/fortnite-creative-mode.jpg 320w,
                    https://cdn.eu.com/c/islandcodes/media/img/640/fortnite-creative-mode.jpg 640w,
                    https://cdn.eu.com/c/islandcodes/media/img/768/fortnite-creative-mode.jpg 768w,
                    https://cdn.eu.com/c/islandcodes/media/img/1024/fortnite-creative-mode.jpg 1024w,
                    https://cdn.eu.com/c/islandcodes/media/img/1366/fortnite-creative-mode.jpg 1366w,
                    https://cdn.eu.com/c/islandcodes/media/img/1600/fortnite-creative-mode.jpg 1600w,
                    https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg 1920w`
                    }
                    
                    src={map.images && map.images.main ? "https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg" + map.images.main + ".jpg" :"https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg"}
                    alt=""
                    className="w-100 z-depth-2"
                        
                    />
                </picture>
                    
                    <h1 className="text-uppercase font-weight-bold mt-3 mapBlockName">{map.name}</h1>
                    <Link to={"/creator/" + map.creator}>
                        <h3 className="text-uppercase font-weight-bold mt-3 black-text mapBlockCreator">{map.creator}</h3>
                    </Link>
                    <hr className="my-0 my-lg-3" />
                    <Link to={"/map/" + map.code}>
                        <div className="fortnite-btn w-75 mx-auto">
                            <span className="text-uppercase mapBlockCreator">Show Island</span>
                        </div>
                    </Link>
                </MDBCardBody>
            </MDBCard>
        )
    }



    render() {
        return (
            <Fragment>
                <MDBRow>
                    <MDBCol className="headingBlue text-center firstBlock px-0">
                        <h1 className="display-3 display1-md text-uppercase white-text featuredHeading">featured maps</h1>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                
                    <MDBCol className="FeaturedBlock py-3 py-sm-4 py-md-5 text-center">
                        <MDBRow>

                        <MDBCol size="12">
                            <Carousel items={!this.state.isLoading && this.renderFeaturedCards(this.state.maps)} />
                        </MDBCol>

                        </MDBRow>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="py-3 py-sm-4 py-md-5 allMapsArea">
                    <MDBCol>
                        <MDBRow>
                            {!this.state.isLoading && this.renderMapsList(this.state.maps)}
                        </MDBRow>

                    </MDBCol>
                </MDBRow>
                {this.state.theBlock &&
                
                <div className="BlockWinnerOuter">
                    <MDBCol className="text-center p-5 white-text">
                        <h2 className="display-3 display1-md text-uppercase white-text featuredHeading">the block winner</h2>
                        <img
                        sizes="(max-width: 1366px) 100vw, 1366px"
                        srcSet={
                        `
                        https://cdn.eu.com/c/islandcodes/media/img/320/`+this.state.theBlock.code+`main.jpg 320w,
                        https://cdn.eu.com/c/islandcodes/media/img/640/`+this.state.theBlock.code+`main.jpg 640w,
                        https://cdn.eu.com/c/islandcodes/media/img/768/`+this.state.theBlock.code+`main.jpg 768w,
                        https://cdn.eu.com/c/islandcodes/media/img/1024/`+this.state.theBlock.code+`main.jpg 1024w,
                        https://cdn.eu.com/c/islandcodes/media/img/1366/`+this.state.theBlock.code+`main.jpg 1366w`
                        }
                        alt={this.state.theBlock.name + " by " + this.state.theBlock.creator}
                        className="mx-auto z-depth-2 px-0 col-12 col-md-6"></img>
                        
                        <h1 className="text-uppercase font-weight-bold mt-3 display-3 display1-md text-shadow">{this.state.theBlock.name}</h1>
                        <h2 className="text-uppercase font-weight-bold mt-3 text-shadow">Creator: {this.state.theBlock.creator}</h2>
                        <Link to={"/map/" + this.state.theBlock.code}>
                        <div className="fortnite-btn">
                            <span className="text-uppercase">Show Island</span>
                        </div>
                        </Link>
                        <img
                        sizes="(max-width: 1000px) 100vw, 520px"
                        srcSet="
                        https://cdn.eu.com/c/islandcodes/img/res/figure-block_ygenww_c_scale%2Cw_100.png 100w,
                        https://cdn.eu.com/c/islandcodes/img/res/figure-block_ygenww_c_scale%2Cw_228.png 460w,
                        https://cdn.eu.com/c/islandcodes/img/res/figure-block_ygenww_c_scale%2Cw_317.png 520w,
                        https://cdn.eu.com/c/islandcodes/img/res/figure-block_ygenww_c_scale%2Cw_393.png 760w,
                        https://cdn.eu.com/c/islandcodes/img/res/figure-block_ygenww_c_scale%2Cw_460.png 900w,
                        https://cdn.eu.com/c/islandcodes/img/res/figure-block_ygenww_c_scale%2Cw_520.png 1000w"
                        src="https://cdn.eu.com/c/islandcodes/img/res/figure-block_ygenww_c_scale%2Cw_520.png"
                        alt="Fortnite Creative The Block"
                        className="float-right figureBlock"></img>
                        
                    </MDBCol>
                </div>

                }   

                <MDBRow className="goldenBG">
                    <MDBCol className="px-0">
                        <MDBRow className="mx-auto p-3 p-sm-5">
                            <MDBCol className="px-0">
                                <div className="fortnite-btn">
                                    <span className="text-uppercase">event submissions</span>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="fortnite-btn">
                                    <span className="text-uppercase">creators</span>
                                </div>
                            </MDBCol>

                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
                />
            </Fragment>
        )
    }
};

export default AllMaps;