import React, {Fragment} from "react";
import axios from "axios";
import { 
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBContainer
     } from "mdbreact";
import ReactGA from 'react-ga';
const apiPathContact = "contact/message";


class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            creatorTag: "",
            epicUsername: "",
            hasSendReqMan: false
        }
    };
    changeEpicUsername = (e) => {
        let userInfo = this.state.userInfo;
        userInfo.epicUsername = e.target.value;
        this.setState({userInfo});
    }
    changeMessage = (e) => {
        this.setState({[e.target.id]: e.target.value});
    }

    postMessage = () => {
        this.setState({hasSendReqMan: true});
        const options = {
            body: {
                contact: this.state.contact,
                message: this.state.message
            }
        }
        axios({
            method: "post",
            url: "https://icapi.cdn.eu.com/api/" + apiPathContact,
            data: options.body,
            crossDomain: true
          });
        ReactGA.event({
            category: 'Contact',
            action: 'submit form'
          });
    }
    
    renderPage() {
        return (
        
            <Fragment>
                <MDBRow>
                <MDBCol className="headingBlue text-center firstBlock">
                <h1 className="display-3 display1-md text-uppercase white-text featuredHeading">Contact us:</h1>
                </MDBCol>
                </MDBRow>
                <MDBRow>
                <MDBCol className="searchBlock">
                    <MDBRow>
                        <MDBCol>
                        <MDBContainer>
                        <MDBCard className="my-5 text-center">
                                <MDBCardBody>
                                        <div className="mt-4">
                            <h4>Fill out this form to contact us:</h4>
                            <p>Please tell us what we can do for you and how you want us to contact you back.</p>
                            <MDBRow>
                                <MDBCol size="12" sm="8" md="6" className="mx-auto">
                                    
                                    <MDBInput
                                    label="Contact Info (Email or Discord)"
                                    id="contact"
                                    value={this.state.contact}
                                    onChange={this.changeMessage}/>
                                    <MDBInput
                                        type="textarea"
                                        rows="3"
                                        label="Your Message"
                                        id="message"
                                        value={this.state.message}
                                        onChange={this.changeMessage}/>
                                    <button
                                        className="fortnite-btn border-0 fortnite-btn-sm"
                                        onClick={this.postMessage}
                                        disabled={this.state.hasSendReqMan}
                                        >
                                        
                                        <span className="text-uppercase">{this.state.hasSendReqMan ? "Message sent successfully": "Send Message"}</span>
                                    </button>
                                </MDBCol>
                            </MDBRow>
                        </div>
                                
                                </MDBCardBody>
                            
                            </MDBCard>
                        </MDBContainer>
                        </MDBCol>
                    </MDBRow> 
                </MDBCol>
                </MDBRow>

            
                <MDBRow className="goldenBG">
                <MDBCol>
                <MDBRow className="mx-auto p-2 p-sm-5">
                            <MDBCol>
                            <div className="fortnite-btn">
                            <span className="text-uppercase">how to use a code</span>
                            </div>
                            </MDBCol>
                            <MDBCol>
                            <div className="fortnite-btn">
                            <span className="text-uppercase">how to create a code</span>
                            </div>
                            </MDBCol>
                            
                        </MDBRow>
                </MDBCol>
                </MDBRow>
            </Fragment>
        
        );
    }

    render() {
    return (
      <div className="CreateMap">
        {this.renderPage()}
        
      </div>
    );
  }
}

export default Contact;