import React, {Fragment, Suspense} from "react";
import {
    MDBRow,
    MDBCol,
    MDBIcon,
    ToastContainer,
    toast
} from "mdbreact";
import {Link} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MetaTags from 'react-meta-tags';
import AdSense from 'react-adsense';
import axios from 'axios';
import MapBlock from '../components/MapBlock';
import "./Map.css";
const Lightbox = React.lazy(() => import('react-image-lightbox'));


class SingleMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            code: this
                .props
                .location
                .pathname
                .substring(5),
            photoIndex: 0,
            isOpen: false

        }
    };

    componentWillReceiveProps(props) {
        var code = props
            .location
            .pathname
            .substring(5);
        this.setState({code: code});
        this.getMaps(code);
    }

    async componentDidMount() {
        this.getMaps(this.state.code)
    }

    getMaps = async(code) => {

        const options = {
            'queryStringParameters': {
                'code': code
            }
        };

        try {
            const mapRes = await axios.get("https://icapi.cdn.eu.com/api/maps/map", {
                params: {code: options.queryStringParameters.code},
                crossDomain: true
            });
            const map = mapRes.data.Items;
            this.setState({map});

            const allMapsOptions = {
                'queryStringParameters': {
                    'creator': map[0].creator
                }
            }
            const mapsRes = await axios.get("https://icapi.cdn.eu.com/api/creator/maps", {
                params: {creator: allMapsOptions.queryStringParameters.creator},
                crossDomain: true
            });
            const maps = mapsRes.data.Items
            this.setState({maps});
            window.scrollTo(0, 0)
        } catch (e) {
            console.log(e);
            toast.warning('Error fetching map, please try again', {
                position: "top-right",
              });
        }

        this.setState({isLoading: false});
    }

    renderMapsList(maps) {
        return maps.map(map => {
            return (
                <MDBCol md="4" className="my-4" key={map.code}>
                    <MapBlock
                        history={this.props.history}
                        name={map.name}
                        creator={map.creator}
                        code={map.code}
                        gameMode={map.gameModes}
                        img={map.images && map.images.main}/>
                </MDBCol>
            )})
    }

    renderAddImages(images) {
        let md;
        switch(images.length) {
          case 1: md = 10; break;
          case 2: md = 6; break;
          case 3: md = 4; break;
          default: md = 3;
        }
        return images.map(i => {
            return (
                <MDBCol size="12" sm="6" md={md.toString()} className="my-3 px-3 mx-auto" key={i}>
                  <figure className="mx-auto float-none">
                      <img
                        src={"https://cdn.eu.com/c/islandcodes/media/img/768/" + i + ".jpg"}
                        alt="Gallery"
                        className="img-fluid z-depth-2"
                        onClick={() =>
                          this.setState({ lightBox: {main: i}, isOpen: true })
                        }
                      />
                  </figure>
                   
                </MDBCol>
            )})
    }

    renderMap(map) {
        const m = map[0];
        const gameModes = m.gameModes
            ? m
                .gameModes
                .map(mode => {
                    return mode + "  "
                })
            : "Not Set  ";
        return (
            <Fragment>
             <MetaTags>
                <title>{"Fortnite Creative - "+m.name.toUpperCase()+" by: "+m.creator.toUpperCase()}</title>
                <meta name="description" content={m.description} />
                <meta property="og:title" content={m.name.toUpperCase()+" by: "+m.creator.toUpperCase()} />
                <meta property="og:image" content={m.images && m.images.main
                                    ? "https://cdn.eu.com/c/islandcodes/media/img/1920/" + m.images.main + ".jpg"
                                    : "https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg"} />
            </MetaTags>
                <MDBRow>
                    {/*<MDBCol className="nameBlock text-center firstBlock"></MDBCol> */}
                </MDBRow>
                <MDBRow>
                    <MDBCol className="mapArea">
                        <h1
                            className="display1-md text-uppercase white-text text-center mt-5 featuredHeading">{m.name}</h1>
                        <MDBRow className="w-100 mx-0 smooth-scroll">
                            <MDBCol size="12" lg="6" className="p-3 p-sm-3 p-md-5">
                                <img
                                    src={m.images && m.images.main
                                    ? "https://cdn.eu.com/c/islandcodes/media/img/1920/" + m.images.main + ".jpg"
                                    : "https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg"}
                                    className="w-100 z-depth-5"
                                    alt={"Fortnite Creative - "+m.name}
                                    onClick={() =>
                                    {
                                      if (m.images.main) {
                                      this.setState({ lightBox: {main: m.images.main}, isOpen: true })
                                    }}}
                                    />
                                    {(m.images && m.images.additional) || m.youtubeID ? <h5 className="float-right white-text mt-3 mb-0" onClick={() => {document.getElementById("mediaArea").scrollIntoView({block: "start"});}}>More Media</h5>: ""}
                                  
                            </MDBCol>
                            <MDBCol size="12" lg="6" className="p-2 p-sm-4 p-md-5 singleMapDesc">
                            <div
                            style={{
                                        backgroundColor: "#ffffff8f",
                                        clipPath: "polygon(0 100%,2% 5%,90% 0,90% 5%,93% 0,100% 0,96% 100%)"
                                    }}
                            className="p-4 p-md-5 text-center"
                            >
                            <MDBRow className="my-2">
                              <MDBCol size="12" xl="4">
                              <h2>Description:</h2>
                              </MDBCol>
                              <MDBCol size="12" xl="8">
                              <h4>{m.description}</h4>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="my-2">
                              <MDBCol size="12" xl="4">
                              <h2>Game Mode:</h2>
                              </MDBCol>
                              <MDBCol size="12" xl="8">
                              <h4>
                              {m.gameModes
                                    ? gameModes
                                    : "Not set"}
                              </h4>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="my-2">
                              <MDBCol size="12" xl="4">
                              <h2>Creator:</h2>
                              </MDBCol>
                              <MDBCol size="12" xl="8">
                                <Link to={"/creator/" + m.creator} className="z-depth-3" style={{fontSize: "1.5rem"}}>
                                <span className="gameModeBadge text-uppercase black-text" style={{backgroundColor: "#f0ed63", paddingLeft: "3rem", paddingRight: "3rem", paddingTop: ".7rem", paddingBottom: ".7rem", color: "#000!important"}} >{m.creator}</span>
                                </Link>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-5">
                                              <MDBCol size="6" className="px-1">
                                                  <CopyToClipboard text={this.state.code}>
                                                      <div
                                                          className="fortnite-btn mx-auto my-1 my-sm-2"
                                                          style={{maxWidth: "100%"}}
                                                          onClick={() => {
                                                          toast.success('Copied Code.', {position: "top-right"})
                                                      }}>
                                                          <span className="text-uppercase mapBlockCreator">{this.state.code}</span>
                                                      </div>
                                                  </CopyToClipboard>
                                              </MDBCol>
                                              <MDBCol size="6" className="px-1">
                                                  <a href={"https://fortnite.com/fn/" + m.code}>
                                                      <div className="fortnite-btn mx-auto my-1 my-sm-2" style={{maxWidth: "100%"}}>
                                                          <span className="text-uppercase mapBlockCreator">Add to account</span>
                                                      </div>
                                                  </a>
                                              </MDBCol>
                                          </MDBRow>
                              
                            </div>
                                    

                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol id="mediaArea" className="mediaArea text-center firstBlock">
                        {m.youtubeID
                            ? <iframe
                                    className="mapVideo"
                                    src={"https://www.youtube.com/embed/" + m.youtubeID}
                                    frameBorder="0"
                                    title={m.name}
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            : <img
                                src={m.images && m.images.main
                                ? "https://cdn.eu.com/c/islandcodes/media/img/1920/" + m.images.main + ".jpg"
                                : "https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg"}
                                className="w-100 z-depth-5 z-depth-5 mapAltImg"
                                alt={m.name}
                                onClick={() =>
                                    {
                                      if (m.images.main) {
                                      this.setState({ lightBox: {main: m.images.main}, isOpen: true })
                                    }}}
                                />
}

                        {m.images && m.images.additional
                            ? <div className="mdb-lightbox no-margin">
                                    <MDBRow className="p-0 p-sm-2 p-md-4 p-lg-5 w-75 mx-auto">
                                        {this.renderAddImages(m.images.additional)}
                                    </MDBRow>
                                </div>
                            : <h4 className="text-white my-5"><MDBIcon icon="images"/>
                                No additional images</h4>
}
                        <div className="mx-auto mb-3" style={{maxWidth: "1200px"}}>
                        <AdSense.Google
                        client='ca-pub-9509107377424765'
                        slot='5517933027'
                        style={{ display: 'block' }}
                        format='auto'
                        responsive='true'
                        />
                        </div>
                    </MDBCol>
                </MDBRow>
            </Fragment>
        )
    }

    renderLoader() {
        return (
            <Fragment>
                <MDBRow></MDBRow>
                <MDBRow>
                    <MDBCol className="mapArea mt-4">
                        <div className="w-100 text-center text-white my-5">
                            <div className="spinner-grow" role="status">
                                <span className="sr-only">Loading Map...</span>
                            </div>
                            <h1>Loading Map</h1>
                        </div>
                    </MDBCol>
                </MDBRow>
            </Fragment>
        )
    }

    render() {
        return (

            <Fragment>
                {this.state.isLoading
                    ? this.renderLoader()
                    : this.renderMap(this.state.map)}
                <MDBRow>
                    <MDBCol className="creatorMapsArea py-5">
                        <MDBRow>
                            <MDBCol size="12">
                                <h2 className="white-text">ALL MAPS Of THIS CREATOR</h2>
                            </MDBCol>

                            {!this.state.isLoading && this.renderMapsList(this.state.maps)}
                        </MDBRow>
                        <div className="mx-auto" style={{maxWidth: "1200px"}}>
                        <AdSense.Google
                        client='ca-pub-9509107377424765'
                        slot='6848817250'
                        style={{ display: 'block'}}
                        format='auto'
                        responsive='true'
                        />
                        </div>
                    </MDBCol>
                </MDBRow>
                <ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={3000}/>
                {this.state.isOpen && (
                    <Suspense fallback={<div>Loading...</div>}>
                
                <Lightbox
                  mainSrc={"https://cdn.eu.com/c/islandcodes/media/img/1920/" + this.state.lightBox.main + ".jpg"}
                  //nextSrc={images[(photoIndex + 1) % images.length]}
                  //prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                  imageTitle="Additional Image"
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  // onMovePrevRequest={() =>
                  //   this.setState({
                  //     photoIndex: (photoIndex + images.length - 1) % images.length
                  //   })
                  // }
                  // onMoveNextRequest={() =>
                  //   this.setState({
                  //     photoIndex: (photoIndex + 1) % images.length
                  //   })
                  // }
                />
                </Suspense>
              )}
             
            </Fragment>

        )
    }
};

export default SingleMap;