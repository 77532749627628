import React from "react";
import { MDBCol, MDBRow, MDBFooter, MDBIcon } from "mdbreact";
import {Link} from 'react-router-dom';

const FooterPage = () => {
  return (
    <MDBFooter color="elegant-color-dark" style={{
      fontFamily: "OpenSans,sans-serif",
      fontSize: "0.8rem"
    }}>
      <div className="footer-copyright text-center py-3">
        <MDBRow className="mx-0">
          <MDBCol size="12" sm="3">
          <div className="mx-auto px-5" style={{fontSize: "1.2rem"}}>
          <MDBIcon fab icon="discord" className="mx-3" onClick={() => { window.open("https://discord.gg/yyWnudA", "_blank")}}/><MDBIcon fab icon="instagram" className="mx-3" onClick={() => { window.open("https://www.instagram.com/fortnite_islandcodes/", "_blank")}} /><MDBIcon fab icon="youtube" className="mx-3" onClick={() => { window.open("https://www.youtube.com/channel/UCFdH7BM7PzYaxhS2TacIZ-g", "_blank")}} />
          </div>
          
          </MDBCol>
          <MDBCol size="12" sm="6">
          <span className="align-middle">
          &copy; {new Date().getFullYear()} ISLAND CODES | FORTNITE IS A REGISTERED TRADEMARK OF EPIC GAMES
          </span>
         
          </MDBCol>
          <MDBCol size="12" sm="3">
          <Link to="/contact" className="white-text">CONTACT</Link> | PREMIUM | <span className="white-text" onClick={() => { window.open("https://discord.gg/yyWnudA", "_blank")}}>SUPPORT</span>
          </MDBCol>
        </MDBRow>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;