import React, { Fragment } from "react";
import {  MDBRow, MDBCol, MDBTable, MDBTableBody, MDBIcon, ToastContainer, toast, MDBTooltip } from "mdbreact";
import axios from "axios";
import AdSense from 'react-adsense';
import MapBlock from '../components/MapBlock';
import MetaTags from 'react-meta-tags';
import "./Map.css";
const apiPathMaps = "creator/maps";
const apiPathCr = "creator/single";

class Creator extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          isLoading: true,
          creator: this.props.location.pathname.substring(9)

      }
  };

  async componentDidMount() {

    const options = { 
        'queryStringParameters': {
            'name': this.state.creator
        }
    };
   this.getCreatorAndMaps(options);
    }

  getCreatorAndMaps = async () => {
    const options = { 
      'queryStringParameters': {
          'name': this.state.creator
      }
    };
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
    }
    try {
      const creatorRes = await axios.get("https://icapi.cdn.eu.com/api/" + apiPathCr, {
        params: options.queryStringParameters,
        crossDomain: true
    });
      const creator = creatorRes.data.Item ? creatorRes.data.Item : this.props.location.pathname.substring(9);
      this.setState({ creator });

      const allMapsOptions = {
        'queryStringParameters': {
          'creator': creator.username ? creator.username : creator
        }
      }
      const mapsRes = await axios.get("https://icapi.cdn.eu.com/api/" + apiPathMaps, {
        params: allMapsOptions.queryStringParameters,
        crossDomain: true
    });
      const maps = mapsRes.data.Items
      this.setState({ maps });

  } catch (e) {
    console.log(e);
    toast.warning('Error fetching creator, please try again', {
        position: "top-right",
      });
  }

  this.setState({ isLoading: false });
  }


  


  handleChangeFilter = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  renderMapsList(maps) {
    return maps.map(map => {
        return (
            <MDBCol md="4" className="my-4" key={map.code}>
            <MapBlock history={this.props.history} name={map.name} creator={map.creator} code={map.code} gameMode={map.gameModes} img={map.images && map.images.main} />
            </MDBCol>
    );})
}

renderAddImages(images) {
  return images.map(i => {
      return (
        <MDBCol>
        <img src={i} className="w-100 z-depth-2" alt="" />
        </MDBCol> 
      )})
}

renderMap(map) {
  const m = map ? map : {}; //creator
  console.log("M: " + m);
  return (
    <Fragment>
     <MetaTags>
                <title>{"Fortnite Creative Creator - "+ m.username || this.props.location.pathname.substring(9)}</title>
                <meta name="description" content={m.description ? m.description : m.username || this.props.location.pathname.substring(9)+" island codes"} />
                <meta property="og:title" content={"Creator "+m.username || this.props.location.pathname.substring(9)} />
                <meta property="og:image" content={m.image ? "https://cdn.eu.com/c/islandcodes/media/img/creators/500/"+ m.image +".jpg" : "https://cdn.eu.com/c/islandcodes/media/img/sys/creator_default.jpg"}
                 />
            </MetaTags>
        <MDBRow>
        {/*<MDBCol className="nameBlock text-center firstBlock"></MDBCol> */}
        </MDBRow>
        <MDBRow>
          <MDBCol className="mapArea">
          <h1 className="display-1 text-uppercase white-text text-center mt-5 featuredHeading">{m.username || this.props.location.pathname.substring(9)}</h1>
            <MDBRow className="mx-0">
              <MDBCol size="12" lg="6" className="p-5 text-center">
                <img src={m.image ? "https://cdn.eu.com/c/islandcodes/media/img/creators/500/"+ m.image +".jpg" : "https://cdn.eu.com/c/islandcodes/media/img/sys/creator_default.jpg"}
                className="z-depth-5 rounded-circle img-fluid mx-auto" alt={m.username || this.props.location.pathname.substring(9)} width="250" height="250" />
              </MDBCol>
              <MDBCol size="12" lg="6" className="p-4 p-md-5 singleMapDesc">
                            <div
                            style={{
                                        backgroundColor: "#ffffff8f",
                                        clipPath: "polygon(0 100%,2% 5%,90% 0,90% 5%,93% 0,100% 0,96% 100%)"
                                    }}
                            className="p-5"
                            >
                            <MDBRow className="my-2">
                              <MDBCol size="12" xl="4">
                              <h2>Description:</h2>
                              </MDBCol>
                              <MDBCol size="12" xl="8">
                              <h4>{m.description}</h4>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="my-2">
                              <MDBCol size="12" xl="4">
                              <h2>Creator Code:</h2>
                              </MDBCol>
                              <MDBCol size="12" xl="8">
                              <h2 className="text-uppercase">
                              {m.username || this.props.location.pathname.substring(9)}
                              </h2>
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="my-2">
                              <MDBCol size="12" xl="4">
                              <h2>Social:</h2>
                              </MDBCol>
                              <MDBCol size="12" xl="8">
                              {m.social && this.renderSocial(m.social)}
                              </MDBCol>
                            </MDBRow>
                            
                              
                            </div>
                                    

                            </MDBCol>
              
            </MDBRow>  
          </MDBCol>
        </MDBRow>
      </Fragment>
  )
}

renderSocial(social) {
    const socialCells = Object.keys(social).map(key => {
      if (key === "discord") {
        return <td key={key}>
                <MDBTooltip
                  placement="left"
                  tag="div"
                  tooltipContent={social[key]}>
                  <MDBIcon fab icon={key} />
                  </MDBTooltip>
                </td>
      } else {
        return <td key={key}><a href={"https://" + social[key]} target="_blank" rel="noopener noreferrer" className="text-white"><MDBIcon fab icon={key} /></a></td>
      }
    } 
    );
    const tableStyle = {
        backgroundColor: "transparent"
    };
    return (
        <MDBTable borderless responsive className="text-center text-white" style={tableStyle}>
            <MDBTableBody>
            <tr>
               {socialCells}
            </tr>
            </MDBTableBody>
        </MDBTable>
    )
}

renderLoader() {
  return (
    <Fragment>
        <MDBRow>
        </MDBRow>
        <MDBRow>
          <MDBCol className="mapArea mt-4">
          <div className="w-100 text-center text-white my-5">
            <div className="spinner-grow" role="status">
                <span className="sr-only">Loading Creator...</span>
              </div>
              <h1>Loading Creator</h1>
          </div>
          </MDBCol>
        </MDBRow>
      </Fragment>
  )
}

  render() {
  return (
    
    <Fragment>
      {this.state.isLoading ? this.renderLoader() : this.renderMap(this.state.creator)}
      <MDBRow>
          <MDBCol className="mediaArea p-5">
            <MDBRow className="px-lg-5">           
            <MDBCol size="12">
            <h2 className="white-text">ALL MAPS Of THIS CREATOR</h2>
            </MDBCol>
            
             {!this.state.isLoading && this.renderMapsList(this.state.maps)}
             <MDBCol size="12">
             <div className="mx-auto mb-3" style={{maxWidth: "1200px"}}>
                        <AdSense.Google
                        client='ca-pub-9509107377424765'
                        slot='6571283953'
                        style={{ display: 'block' }}
                        format='auto'
                        responsive='true'
                        />
                        </div>
             </MDBCol>
            </MDBRow>  
          </MDBCol>
        </MDBRow>
        <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
                />
    </Fragment>
        

      
  )
  }
};

export default Creator;