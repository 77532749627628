import React from "react";
import {MDBRow, MDBCol, MDBCard, MDBCardBody} from "mdbreact";
import LazyLoad from 'react-lazyload';
import Carousel from '../components/Carousel';
import "./Home.css";
import {Link} from 'react-router-dom';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    };
    async componentDidMount() {
        
        try {          
            const api_call = await fetch(`https://s3.eu-central-1.amazonaws.com/island-codes.com/media/featured/featuredMaps.json`);
            const maps = await api_call.json();
            this.setState({maps});
        } catch (e) {
            console.log(e);
           
        }

        this.setState({isLoading: false});
        setTimeout(() => {
            window.scrollTop = 0;
            document.getElementById("mainContainer").scrollTop = 0;
        }, 10)
        
    }

    renderFeaturedCards(maps) {
        return maps.map((map, index) => 
            <MDBCard className={"mx-sm-2 mx-xl-5 h-100" + (index % 2 ? " featuredCard1" : " featuredCard2")}>
                <MDBCardBody>
                    <img
                    
                    sizes="(max-width: 2400px) 100vw, 1024px"
                    srcSet={`
                    https://cdn.eu.com/c/islandcodes/media/img/320/`+ map.images.main +`.jpg 320w,
                    https://cdn.eu.com/c/islandcodes/media/img/320/`+ map.images.main +`.jpg 900w,
                    https://cdn.eu.com/c/islandcodes/media/img/640/`+ map.images.main +`.jpg 1300w,
                    https://cdn.eu.com/c/islandcodes/media/img/768/`+ map.images.main +`.jpg 2000w,
                    https://cdn.eu.com/c/islandcodes/media/img/1024/`+ map.images.main +`.jpg 2400w`
                   
                    }
                    
                    src={map.images && map.images.main ? "https://cdn.eu.com/c/islandcodes/media/img/1920/" + map.images.main + ".jpg" :"https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg"}
                    alt={"Fortnite Creative Island " + map.name + "" + map.code}
                    className="w-100 z-depth-2"
                        
                    />
                    <h1 className="text-uppercase font-weight-bold mt-3 mapBlockName">{map.name}</h1>
                    <Link to={"/creator/" + map.creator}>
                        <h3 className="text-uppercase font-weight-bold mt-3 black-text mapBlockCreator">{map.creator}</h3>
                    </Link>
                    <hr className="my-0 my-lg-3" />
                    <Link to={"/map/" + map.code}>
                        <div className="fortnite-btn w-75 mx-auto">
                            <span className="text-uppercase mapBlockCreator">Show Island</span>
                        </div>
                    </Link>
                </MDBCardBody>
            </MDBCard>
        )
    }
    render() {
    return (
        <div>
            <div className="fullHeight view homeFront" style={{minHeight: "350px"}}>
                <div>
                <img
                    sizes="(max-width: 1600px) 100vw, 1600px"
                    srcSet="
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_300.jpg 300w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_437.jpg 600w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_542.jpg 900w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_651.jpg 1000w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_747.jpg 1200w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_838.jpg 1300w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_927.jpg 1400w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_1010.jpg 1500w,
                    https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_1081.jpg 1600w"
                    src="https://cdn.eu.com/c/islandcodes/img/res/mainImage_ez4q9d_c_scale%2Cw_1081.jpg"
                    className="mx-auto mainImage w-100"
                    alt="Fortnite Creative Mode Island Codes"/>
                    
                    <img
                    sizes="(max-width: 2200px) 100vw, 610px"
                    srcSet="
                    https://cdn.eu.com/c/islandcodes/img/res/figure1_rf6vqs_c_scale%2Cw_100.png 320w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure1_rf6vqs_c_scale%2Cw_265.png 1000w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure1_rf6vqs_c_scale%2Cw_371.png 1200w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure1_rf6vqs_c_scale%2Cw_466.png 1600w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure1_rf6vqs_c_scale%2Cw_546.png 1800w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure1_rf6vqs_c_scale%2Cw_610.png 2200w"
                    src="https://cdn.eu.com/c/islandcodes/img/res/figure1_rf6vqs_c_scale%2Cw_610.png"
                    className="float-left figure1"
                    alt="Creative Island Codes" />
                   <img
                    sizes="(max-width: 2200px) 100vw, 692px"
                    srcSet="
                    https://cdn.eu.com/c/islandcodes/img/res/figure2_cwxjsg_c_scale%2Cw_100.png 320w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure2_cwxjsg_c_scale%2Cw_296.png 1000w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure2_cwxjsg_c_scale%2Cw_419.png 1200w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure2_cwxjsg_c_scale%2Cw_528.png 1600w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure2_cwxjsg_c_scale%2Cw_619.png 1800w,
                    https://cdn.eu.com/c/islandcodes/img/res/figure2_cwxjsg_c_scale%2Cw_692.png 2200w"
                    src="https://cdn.eu.com/c/islandcodes/img/res/figure2_cwxjsg_c_scale%2Cw_692.png"
                    className="float-right figure2"
                    alt="The Block" />
                  
                </div>
                <div className="text-center outerButtonAllMaps">
                    <img
                         sizes="(max-width: 1000px) 100vw, 1000px"
                        srcSet="
                        https://cdn.eu.com/c/islandcodes/img/allMapsBtnSM.png 800w,
                        https://cdn.eu.com/c/islandcodes/img/allMapsBtn.png 1000w"
                        src="https://cdn.eu.com/c/islandcodes/img/allMapsBtn.png"
                        className="allMapsBtn mx-auto"
                        alt="Find all Maps"/>
                </div>
            </div>
            <div className="featured text-center pt-0 pt-md-4 pt-xl-5" style={{minHeight: "250px"}}>
                <h1 className="text-uppercase white-text featuredHeading">Featured Maps</h1>
                <MDBRow style={{maxWidth: "100%"}} className="mx-auto">
                    <MDBCol size="12" className="px-1">
                    <Carousel items={!this.state.isLoading && this.renderFeaturedCards(this.state.maps)} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mx-0">
                    <MDBCol className="pr-md-5">
                        <Link to="/maps">
                            <div className="fortnite-btn float-right my-4 mr-5">
                                <span className="text-uppercase">All Maps</span>
                            </div>
                        </Link>

                    </MDBCol>
                </MDBRow>
            </div>
            <div className="addIsland">
                <MDBRow className="mx-0">
                    <MDBCol size="12" sm="5" className="text-center px-5">
                    <LazyLoad>
                        <img
                            sizes="(max-width: 1300px) 100vw, 1300px"
                            srcSet="
                            https://cdn.eu.com/c/islandcodes/img/res/Handy_wsqrnf_c_scale%2Cw_300.png 500w,
                            https://cdn.eu.com/c/islandcodes/img/res/Handy_wsqrnf_c_scale%2Cw_397.png 600w,
                            https://cdn.eu.com/c/islandcodes/img/res/Handy_wsqrnf_c_scale%2Cw_483.png 700w,
                            https://cdn.eu.com/c/islandcodes/img/res/Handy_wsqrnf_c_scale%2Cw_561.png 1000w,
                            https://cdn.eu.com/c/islandcodes/img/res/Handy_wsqrnf_c_scale%2Cw_631.png 1200w,
                            https://cdn.eu.com/c/islandcodes/img/res/Handy_wsqrnf_c_scale%2Cw_708.png 1300w"
                            src="https://cdn.eu.com/c/islandcodes/img/res/Handy_wsqrnf_c_scale%2Cw_708.png"
                            className="handy mx-auto mb-5"
                            alt="Fortnite Creative Tool" />
                        </LazyLoad>
                        <Link to="/maps">
                            <div className="fortnite-btn mt-5">
                                <span className="text-uppercase">View All Islands</span>
                            </div>
                        </Link>
                    </MDBCol>
                    <MDBCol size="12" sm="6" className="text-center">
                    <LazyLoad height={300}> 
                        <img
                         sizes="(max-width: 1800px) 100vw, 1800px"
                        srcSet="
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_300.jpg 300w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_408.jpg 800w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_501.jpg 950w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_586.jpg 1000w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_660.jpg 1200w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_727.jpg 1400w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_806.jpg 1500w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_875.jpg 1600w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_902.jpg 1700w,
                        https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_921.jpg 1800w"
                        src="https://cdn.eu.com/c/islandcodes/img/res/addIslandImages_cgb4ag_c_scale%2Cw_921.jpg"
                        className="my-5 mx-auto addIslandImages"
                        alt="All creative island" />
                    </LazyLoad>
                        <a href="https://creator.island.codes" target="_blank" rel="noopener noreferrer">
                        <div className="fortnite-btn">
                                <span className="text-uppercase">Add your island!</span>
                            </div>
                        </a>
                    </MDBCol>
                </MDBRow>

            </div>
            <div className="ButtonsSection">
                <MDBRow className="mx-0">
                    <div className="mx-auto w-75">
                        <MDBRow>
                        <MDBCol size="12" sm="4" className="px-4 pt-1 text-center">
                        <Link to="/maps" className="black-text">
                            <div className="polyCard1">
                            <h1 className="text-uppercase polyCardText mapBlockCreator">All Maps</h1>   
                                    </div>
                        </Link>
                        </MDBCol>
                        
                            <MDBCol size="12" sm="4" className="px-4 pt-1 text-center">
                            <Link to="/creators" className="black-text">
                                <div className="polyCard2">
                                <h1 className="text-uppercase polyCardText mapBlockCreator">Creators</h1>
                                </div>
                                </Link>
                            </MDBCol>
                        
                        <MDBCol size="12" sm="4" className="px-4 pt-1 text-center">
                        <Link to="/register" className="black-text">
                        <div className="polyCard1">
                        <h1 className="text-uppercase polyCardText mapBlockCreator">Creator Register</h1>
                                    </div>
                                    </Link>
                        </MDBCol>
                        </MDBRow>
                    </div>

                   

                </MDBRow>

            </div>

            <div className="FAQ white-text py-5">
                <MDBRow className="mx-0">
                    <MDBCol className="mx-auto px-2 px-sm-3 px-md-5 text-center" style={{maxWidth: "950px"}}>
                        <h1>FAQ</h1>
                        <h2>What is island-codes.com?</h2>
                        <p>We specialize in the online multiplayer game "Fortnite". Our main focus is on
                            the "Creative" game mode
                            next to the Battle Royale and Save the World modes. In Creative mode
                                everyone can build their own Fortnite World and with a Creator Account "Support
                                a Creator Program" you can make your own created world of the whole Fortnite
                                Community available to play by means of a code. We provide as these codes and
                                let you know by pictures and videos what maps are exactly.</p>
                        <br></br>

                        <h2>Can I find any Island from Fortnite Creative here?</h2>
                        <p>Yes you can, if it was not disabled by the creator on our side. You can easily search under "All Maps" for the map code or the map name.
You can also search for individual game types. We hope you find what you are looking for!</p>
                        <br></br>

                        <h2>I'm a creator, can I upload my maps?</h2>
                        <p>Yes you can, you just have to register with us and verify your account via our Epic Games Launcher Bot "IslandCodesCom".
Then you can add new maps or delete or delete existing ones due to errors or problems. We are very interested
to maintain a nice appearance, so we ask you to make an effort to upload beautiful photos and videos of your maps, it will help you!</p>
                        <br></br>

                        <h2>Can only creator register?</h2>
                        <p>You do not have to be a creator to register, everyone can register and soon be able to fill their own favorites!
So sign up now to join upcoming Island Code events as a Creative Person, and maybe you'll soon have a Creator Account.</p>
                        <br></br>

                        <h2>How can I become a creator?</h2>
                        <p>To become a creator, you must meet the requirements of the "Support A Creator" program or be discovered by an Epic employee.
                        <br />You can also find a quick guide on how to creator an island here.</p>
                        <br></br>
                        
                        
                        <h2>Is Island-Codes affiliated with Epic Games?</h2>
                        <p>We are not affiliated Epic Games, our content is not official or endorsed by Epic Games in any way, but Epic Games is watching you! :wink:</p>
                        <br></br>

                    </MDBCol>

                </MDBRow>

            </div>

        </div>
    )
    }
};

export default Home;