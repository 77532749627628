import React, { Component, Fragment } from "react";
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Routes from "./Routes";
import ScrollTop from "./components/ScrollTop"


class App extends Component {

  render() {
    

    return (
      <Fragment>
      <Navigation>
        <ScrollTop>
        <Routes/>
        <Footer />
        </ScrollTop>
        </Navigation>
        
      </Fragment>
    );
  }
}

export default App;
