import React from "react";
import {MDBCard, MDBCardBody, MDBBadge, toast} from "mdbreact";
import {Link} from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import "./MapBlock.css";

class MapBlock extends React.Component {
    render() {
        const styleCode = {
            right: "0px",
            zIndex: "1",
            fontSize: ".7rem"
        };
        const styleCodeOuter = {
            padding: "5px 0",
            height: "30px",
            marginTop: ".5rem"
        };
        return (
            <MDBCard>
                <div className="float-right position-absolute" style={styleCode}>
                    <CopyToClipboard text={this.props.code}>
                        <div
                            className="fortnite-btn fortnite-btn-code w-100 px-3 codeBlock"
                            style={styleCodeOuter}
                            onClick={() => {
                            toast.success('Copied Code.', {position: "top-right"})
                        }}>
                            <span className="text-uppercase">{this.props.code}</span>
                        </div>
                    </CopyToClipboard>
                </div>
                <div className="mapBlockImage">
                <Link to={"/map/" + this.props.code}>
                <LazyLoad height={300}>
                        <img
                            sizes="(max-width: 1920px) 100vw, 1920px"
                            srcSet={this.props.img
                            ? `https://cdn.eu.com/c/islandcodes/media/img/320/` + this.props.img + `.jpg 320w, https://cdn.eu.com/c/islandcodes/media/img/640/` + this.props.img + `.jpg 640w, https://cdn.eu.com/c/islandcodes/media/img/768/` + this.props.img + `.jpg 768w, https://cdn.eu.com/c/islandcodes/media/img/1024/` + this.props.img + `.jpg 1024w, https://cdn.eu.com/c/islandcodes/media/img/1366/` + this.props.img + `.jpg 1366w, https://cdn.eu.com/c/islandcodes/media/img/1600/` + this.props.img + `.jpg 1600w, https://cdn.eu.com/c/islandcodes/media/img/1920/` + this.props.img + `.jpg 1920w`
                            : `https://cdn.eu.com/c/islandcodes/media/img/320/fortnite-creative-mode.jpg 320w, https://cdn.eu.com/c/islandcodes/media/img/640/fortnite-creative-mode.jpg 640w, https://cdn.eu.com/c/islandcodes/media/img/768/fortnite-creative-mode.jpg 768w, https://cdn.eu.com/c/islandcodes/media/img/1024/fortnite-creative-mode.jpg 1024w, https://cdn.eu.com/c/islandcodes/media/img/1366/fortnite-creative-mode.jpg 1366w, https://cdn.eu.com/c/islandcodes/media/img/1600/fortnite-creative-mode.jpg 1600w, https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg 1920w`}
                            src={this.props.img
                            ? "https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg" + this.props.img + ".jpg"
                            : "https://cdn.eu.com/c/islandcodes/media/img/1920/fortnite-creative-mode.jpg"}
                            alt={this.props.name}
                            className="img-fluid p-1 p-md-2"/>
                </LazyLoad>
                </Link>
                   

                </div>

                <div
                    className="mx-auto text-center"
                    style={{
                    height: "1.2rem"
                }}>
                    <MDBBadge pill color="danger" className="gameModeBadge text-uppercase">{this.props.gameMode
                            ? this.props.gameMode[0]
                            : "Creative"}</MDBBadge>
                </div>
                <MDBCardBody>
                    <div className="card-title text-center mapBlockName">
                        <span>{this.props.name}</span>
                    </div>
                    <div className="text-center black-text mapBlockCreator">
                        <span className="text-uppercase">{this.props.creator}</span>
                    </div>
                    <Link to={"/map/" + this.props.code}>
                        <div className="fortnite-btn w-75 mx-auto">
                            <span className="text-uppercase">Show Island</span>
                        </div>
                    </Link>

                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default MapBlock;