import React from "react";
import {  MDBCard, MDBCardBody, MDBRow, MDBCol, MDBAvatar } from "mdbreact";
import {Link} from 'react-router-dom';

    class CreatorBlock extends React.Component {
      

        render() {
          return(

            <MDBCard>
                <MDBCardBody>
                    <div className="card-title text-center">
                       <h1 className="text-uppercase mapBlockName">{this.props.name}</h1>
                    </div>
                    <MDBRow>
                        <MDBCol size="12">
                        <MDBAvatar className="mx-auto white text-center">
                            <img
                            src={this.props.image ?  "https://cdn.eu.com/c/islandcodes/media/img/creators/300/"+ this.props.image +".jpg" : "https://cdn.eu.com/c/islandcodes/media/img/sys/creator_default.jpg"}
                            alt=""
                            width="200"
                            height="200"

                            className="rounded-circle img-fluid"
                            />
                        </MDBAvatar>
                        </MDBCol>
                        <MDBCol>
                            <h3>Info:</h3>
                            <span className="creatorBlockDesc">{this.props.description || "No creator description added yet."}</span>
                        </MDBCol>
                    </MDBRow>
                    <div className="creatorBlockButtonOuter">
                        <div className="creatorBlockButton">
                            <Link to={"/creator/" + this.props.name}>
                                <div className="fortnite-btn w-75 mx-auto mt-4">
                                    <span className="text-uppercase creatorBlockButtonShow">Show Creator</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                   
                </MDBCardBody>
            </MDBCard>   
          );
        }
      }

export default CreatorBlock;