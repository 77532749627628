import React, {Fragment} from "react";
import {MDBRow, MDBCol, MDBInput, MDBCard, MDBCardBody, MDBCollapse, ToastContainer, toast} from "mdbreact";
import axios from "axios";
import MapBlock from '../components/MapBlock';
import AdSense from 'react-adsense';
import {Link} from 'react-router-dom';
import "./AllMaps.css";

const apiPath = "maps";

class AllMaps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            code: "",
            gamemode: [],
            hasMedia: "",
            perPage: 15,
            lastItem: "",
            searchField: "",
            isSearch: false,
            queryParams: window.location.search.substring(3)

        }
    };

    componentDidMount = async () => {
        if(this.state.queryParams.length > 3) {
            this.handleSearchField(this.state.queryParams);
        } else {
            this.getInitMaps();
        }

    }

    getInitMaps = async() => {
        if (!this.state.isLoading) {
            this.setState({isLoading: true});
        }
        const options = {
            'queryStringParameters': {
                'perPage': this.state.perPage
            }
        };

        try {
            const mapsRes = await axios.get("https://icapi.cdn.eu.com/c1/" + apiPath, {
                params: options.queryStringParameters,
                crossDomain: true
            });
            const maps = mapsRes.data.Items
            this.setState({maps});
            this.setState({lastItem: mapsRes.data.LastEvaluatedKey});
        } catch (e) {
            console.log(e);
            toast.warning('Error fetching maps, please try again', {
                position: "top-right",
              });
        }
        setTimeout(() => {
            this.setState({isLoading: false});
            }, 100)
      
    }

    toFirstPage() {
        this.getInitMaps();
        setTimeout(() => {
            window.scrollTop = 100;
        document.getElementById("mapsArea").scrollIntoView({block: "start"});
        window.scrollBy(0, -100);
        }, 10)
    }

    getFeaturedMaps = async() => {
        try {
            const api_call = await fetch(`https://island-codes.com/media/featured/featuredMaps.json`);
            const maps = await api_call.json();
            this.setState({maps});
        } catch (e) {
            console.log(e);
            toast.warning('Error fetching maps, please try again', {
                position: "top-right",
              });
        }
    }
  

    nextPage = async() => {
        this.setState({isLoading: true});
        const options = {
            'queryStringParameters': {
                'gamemode': this.state.gamemode,
                'hasMedia': this.state.hasMedia,
                'perPage': this.state.perPage,
                'lastItem': JSON.stringify(this.state.lastItem),
                'isSearch': this.state.isSearch
            }
        };

        try {
            const mapsRes = await axios.get("https://icapi.cdn.eu.com/c1/" + apiPath, {
                params: options.queryStringParameters,
                crossDomain: true
            });
            const maps = mapsRes.data.Items
            this.setState({maps});
            if (mapsRes.data.LastEvaluatedKey) {
                this.setState({lastItem: {
                    creator: mapsRes.data.LastEvaluatedKey.creator,
                    code: mapsRes.data.LastEvaluatedKey.code,
                    sortKey: mapsRes.data.LastEvaluatedKey.sortKey,
                    active: 1
                    }});
            } else {
                this.setState({lastItem: ""});
            }
            setTimeout(() => {
            document.getElementById("mapsArea").scrollIntoView({block: "start"});
            window.scrollBy(0, -60);
            }, 10)
        } catch (e) {
            console.log(e);
            toast.warning('No more maps.', {
                position: "top-right",
              });
        }
        this.setState({isLoading: false});
    }

    handleSearchField = async(query) => {
        this.setState({isLoading: true});
        try {
            const mapsRes = await axios.get("https://icapi.cdn.eu.com/api/maps/search", {
                params: {q: query ? query.toLowerCase() : this.state.searchField.toLowerCase(), gamemode: this.state.gamemode[0]},
                crossDomain: true
            });
            const maps = mapsRes.data;
            this.setState({maps: maps});
                this.setState({lastItem: ""});
            setTimeout(() => {
            document.getElementById("mapsArea").scrollIntoView({block: "start"});
            }, 10)
        } catch (e) {
            console.log(e);
            toast.warning('No maps found, change search parameters', {
                position: "top-right",
              });
        }
        this.setState({isLoading: false});
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          this.handlesearch();
        }
      }
    

    handlesearch = async() => {
        if(this.state.searchField.length > 0){
            this.handleSearchField();
        } else {
        this.setState({isLoading: true});
        const options = {
            'queryStringParameters': {
                'code': this.state.code,
                'gamemode': this.state.gamemode.toString(),
                'hasMedia': this.state.hasMedia,
                'perPage': this.state.perPage,
                'isSearch': true
            }
        };
        try {
            const mapsRes = await axios.get("https://icapi.cdn.eu.com/c1/" + apiPath, {
                params: options.queryStringParameters,
                crossDomain: true
            });
            const maps = mapsRes.data.Items;
            console.log(mapsRes);
            this.setState({maps: maps});
            if (mapsRes.data.LastEvaluatedKey) {
                this.setState({lastItem: {
                    creator: mapsRes.data.LastEvaluatedKey.creator,
                    code: mapsRes.data.LastEvaluatedKey.code,
                    sortKey: mapsRes.data.LastEvaluatedKey.sortKey,
                    active: 1
                    },
                    isSearch: true
                });
            } else {
                this.setState({lastItem: ""});
            }
           
            setTimeout(() => {
            document.getElementById("mapsArea").scrollIntoView({block: "start"});
            }, 10)
        } catch (e) {
            console.log(e);
            toast.warning('No maps found, change search parameters', {
                position: "top-right",
              });
        }
        this.setState({isLoading: false});
    }
    }

    handleChangeFilter = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleChangeHasMedia = event => {
        this.setState({
            hasMedia: !this.state.hasMedia
        });
    }

    handleChangeGamemodes = e => {
        // current array of options
        let gamemode = [
            e
                .target
                .id
                .substring(7)
        ]
        if (!e.target.checked) {
            gamemode = []
        }
        this.setState({gamemode: gamemode})

    }

    toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

    renderMapsList(maps) {
        let mapCols = maps.map(map => {
            return (
                <MDBCol size="12" sm="6" md="4" xl="3" className="my-2 my-sm-3 my-xl-4" key={map.code}>
                    <MapBlock
                        history={this.props.history}
                        name={map.name}
                        creator={map.creator}
                        code={map.code}
                        gameMode={map.gameModes}
                        img={map.images && map.images.main}/>
                </MDBCol>
            )});
         mapCols.splice(9, 0, this.renderAdd());
        return mapCols;
    }

    renderLoader() {
        return (
            <Fragment>
                <div className="w-100 text-center text-white my-5">
                    <div className="spinner-grow" role="status">
                        <span className="sr-only">Loading Maps...</span>
                    </div>
                    <h1>Loading Maps</h1>
                </div>
            </Fragment>
        )
    }

    renderAdd = () => {
        return(
            <MDBCol size="12" sm="6" md="4" xl="3" className="my-2 my-sm-3 my-xl-4" key="add">
                <MDBCard>
                    <MDBCardBody style={{minWidth: "200px", minHeight: "300px"}}>
                   {!this.state.isLoading &&
                    <AdSense.Google
                            client='ca-pub-9509107377424765'
                            slot='4589285732'
                            style={{ display: 'block', minWidth: "200px", minHeight: "300px"}}
                        responsive='true'
                        format='auto'
                            />
                   }
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        )
    }

    render() {
        const { collapseID } = this.state;
        return (
            <Fragment>
                <MDBRow>
                    <MDBCol className="headingBlue text-center firstBlock">
                        <h1 className="display-2 display-1-md text-uppercase white-text featuredHeading">All Maps</h1>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="searchBlock">
                        <MDBRow className="searchBlockRow">
                            <div className="mx-auto mt-2 mt-sm-4 mb-2 py-3 innerSearchBlock">

                                <MDBRow>
                                    <MDBCol size="12" sm="5" xl="3">
                                        <MDBInput
                                            label="SEARCH CODE: XXXX-XXXX-XXXX"
                                            size="lg"
                                            id="code"
                                            value={this.state.code}
                                            onChange={this.handleChangeFilter}/>
                                    </MDBCol>
                                    <MDBCol size="12" sm="7" xl="6">
                                        <MDBInput
                                            label="SEARCH MAP"
                                            size="lg"
                                            id="searchField"
                                            value={this.state.searchField}
                                            onChange={this.handleChangeFilter}
                                            onKeyPress={this._handleKeyPress}
                                            />
                                             
                                    </MDBCol>
                                    <MDBCol size="12" sm="12" xl="3" className="searchLarge">
                                        <div className="fortnite-btn w-100" onClick={this.handlesearch}>
                                            <span className="text-uppercase">{this.state.isLoading ? "Loading..." : "Search"}</span>
                                        </div>
                                        <MDBInput
                                            label="Only islands with images"
                                            type="checkbox"
                                            id="hasMedia"
                                            checked={this.state.hasMedia}
                                            onChange={this.handleChangeHasMedia}/>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="gamemodesMobile">
                                <div className="card-header white-text text-center" style={{cursor: "pointer"}} onClick={this.toggleCollapse("collapse1")}><h4 className="mb-0"><i className="fa fa-angle-down"></i> Gamemodes <i className="fa fa-angle-down"></i></h4></div>
            
                                <MDBCollapse id="collapse1" isOpen={collapseID}>
                                    <MDBCardBody>
                                    <MDBRow>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                      <MDBInput label="MINI GAMES" type="checkbox" id="search-Minigames" checked={this.state.gamemode.indexOf("Minigames") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="TEAM DEATHMATCH" type="checkbox" id="search-TeamDeathmatch" checked={this.state.gamemode.indexOf("TeamDeathmatch") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="DEATHRUN" type="checkbox" id="search-Deathrun" checked={this.state.gamemode.indexOf("Deathrun") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="EPIC FEATURED" type="checkbox" id="search-EpicFeatured" checked={this.state.gamemode.indexOf("EpicFeatured") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="FREE FOR ALL" type="checkbox" id="search-FreeForAll" checked={this.state.gamemode.indexOf("FreeForAll") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                      <MDBInput label="RACES" type="checkbox" id="search-Races" checked={this.state.gamemode.indexOf("Races") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="FUN MAPS" type="checkbox" id="search-FunMaps" checked={this.state.gamemode.indexOf("FunMaps") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="ESCAPE" type="checkbox" id="search-Escape" checked={this.state.gamemode.indexOf("Escape") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="PUZZLE" type="checkbox" id="search-Puzzle" checked={this.state.gamemode.indexOf("Puzzle") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="COINS" type="checkbox" id="search-Coins" checked={this.state.gamemode.indexOf("Coins") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                    <MDBInput label="HIDE AND SEEK" type="checkbox" id="search-HideAndSeek" checked={this.state.gamemode.indexOf("HideAndSeek") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="PARKOUR" type="checkbox" id="search-Parkour" checked={this.state.gamemode.indexOf("Parkour") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="MAZES" type="checkbox" id="search-Mazes" checked={this.state.gamemode.indexOf("Mazes") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="REMAKES" type="checkbox" id="search-Remakes" checked={this.state.gamemode.indexOf("Remakes") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="EVENT SUBMISSIONS" type="checkbox" id="search-EventSubmissions" checked={this.state.gamemode.indexOf("EventSubmissions") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                    <MDBInput label="THE BLOCK" type="checkbox" id="search-TheBlock" checked={this.state.gamemode.indexOf("TheBlock") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="ADVENTURE" type="checkbox" id="search-Adventure" checked={this.state.gamemode.indexOf("Adventure") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="EDIT COURSES" type="checkbox" id="search-EditCourses" checked={this.state.gamemode.indexOf("EditCourses") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="AIM COURSES" type="checkbox" id="search-AimCourses" checked={this.state.gamemode.indexOf("AimCourses") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="CHALLENGES (PRIZE POOL)" type="checkbox" id="search-ChallengesPrizepool" checked={this.state.gamemode.indexOf("ChallengesPrizepool") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                  </MDBRow>
                                    </MDBCardBody>
                                </MDBCollapse>
                                </MDBRow>

                                <div className="w-100 my-1 gamemodesLarge">
                                  <MDBRow>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                      <MDBInput label="MINI GAMES" type="checkbox" id="search-Minigames" checked={this.state.gamemode.indexOf("Minigames") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="TEAM DEATHMATCH" type="checkbox" id="search-TeamDeathmatch" checked={this.state.gamemode.indexOf("TeamDeathmatch") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="DEATHRUN" type="checkbox" id="search-Deathrun" checked={this.state.gamemode.indexOf("Deathrun") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="EPIC FEATURED" type="checkbox" id="search-EpicFeatured" checked={this.state.gamemode.indexOf("EpicFeatured") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="FREE FOR ALL" type="checkbox" id="search-FreeForAll" checked={this.state.gamemode.indexOf("FreeForAll") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                      <MDBInput label="RACES" type="checkbox" id="search-Races" checked={this.state.gamemode.indexOf("Races") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="FUN MAPS" type="checkbox" id="search-FunMaps" checked={this.state.gamemode.indexOf("FunMaps") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="ESCAPE" type="checkbox" id="search-Escape" checked={this.state.gamemode.indexOf("Escape") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="PUZZLE" type="checkbox" id="search-Puzzle" checked={this.state.gamemode.indexOf("Puzzle") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="COINS" type="checkbox" id="search-Coins" checked={this.state.gamemode.indexOf("Coins") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                    <MDBInput label="HIDE AND SEEK" type="checkbox" id="search-HideAndSeek" checked={this.state.gamemode.indexOf("HideAndSeek") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="PARKOUR" type="checkbox" id="search-Parkour" checked={this.state.gamemode.indexOf("Parkour") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="MAZES" type="checkbox" id="search-Mazes" checked={this.state.gamemode.indexOf("Mazes") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="REMAKES" type="checkbox" id="search-Remakes" checked={this.state.gamemode.indexOf("Remakes") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="EVENT SUBMISSIONS" type="checkbox" id="search-EventSubmissions" checked={this.state.gamemode.indexOf("EventSubmissions") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                    <MDBCol size="6" sm="6" md="3" className="px-0">
                                    <MDBInput label="THE BLOCK" type="checkbox" id="search-TheBlock" checked={this.state.gamemode.indexOf("TheBlock") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="ADVENTURE" type="checkbox" id="search-Adventure" checked={this.state.gamemode.indexOf("Adventure") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="EDIT COURSES" type="checkbox" id="search-EditCourses" checked={this.state.gamemode.indexOf("EditCourses") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="AIM COURSES" type="checkbox" id="search-AimCourses" checked={this.state.gamemode.indexOf("AimCourses") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                      <MDBInput label="CHALLENGES (PRIZE POOL)" type="checkbox" id="search-ChallengesPrizepool" checked={this.state.gamemode.indexOf("ChallengesPrizepool") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                    </MDBCol>
                                  </MDBRow>
                                </div>
                            <MDBRow className="searchMobile">
                            <MDBCol size="12">
                            <MDBInput
                                            label="Only islands with images"
                                            type="checkbox"
                                            id="hasMedia"
                                            checked={this.state.hasMedia}
                                            onChange={this.handleChangeHasMedia}/>
                                        <div className="fortnite-btn w-100" onClick={this.handlesearch}>
                                            <span className="text-uppercase">{this.state.isLoading ? "Loading..." : "Search"}</span>
                                        </div>
                                        
                                    </MDBCol>
                            </MDBRow>
                            </div>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="12" sm="6" xl="4" className="mx-auto">
                                <div className="w-100 my-3">
                                    <MDBRow className="mx-auto">
                                        <MDBCol size="6">
                                            <div className="fortnite-btn w-100" onClick={this.getInitMaps}>
                                                <span className="text-uppercase">NEWEST</span>
                                            </div>
                                        </MDBCol>
                                        <MDBCol size="6">
                                            <div className="fortnite-btn w-100" onClick={this.getFeaturedMaps}>
                                                <span className="text-uppercase">Featured</span>
                                            </div>
                                        </MDBCol>

                                    </MDBRow>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="py-4 allMapsArea w-100" id="mapsArea">
                    <MDBCol>
                        {this.state.isLoading && this.renderLoader()}
                        <MDBRow>
                            {!this.state.isLoading && this.renderMapsList(this.state.maps)}
                        </MDBRow>
                        
                        <MDBRow>
                            <MDBCol size="6">
                                <div className="fortnite-btn w-100" onClick={this.toFirstPage}>
                                    <span className="text-uppercase">{this.state.isLoading ? "Loading..." : "First"}</span>
                                </div>
                               
                            </MDBCol>
                            {this.state.lastItem && 
                            <MDBCol size="6">
                                
                                    <div className="fortnite-btn w-100" onClick={this.nextPage}>
                                    <span className="text-uppercase">{this.state.isLoading ? "Loading..." : "Next"}</span>
                                </div>
                                
                               
                            </MDBCol>
                            }
                        </MDBRow>
                        <div className="mx-auto my-3" style={{width: "100%", minWidth: "100px", maxWidth: "1200px"}}>
                        {!this.state.isLoading &&
                        <AdSense.Google
                        client='ca-pub-9509107377424765'
                        slot='1139704311'
                        style={{ display: 'block', width: "100%", minWidth: "100px" }}
                        responsive='true'
                        format='auto'
                        />
                        }
                        </div>
                    </MDBCol>
                </MDBRow>
               
                <MDBRow className="goldenBG">
                    <MDBCol className="px-0">
                        <MDBRow className="mx-auto p-3 p-sm-5">
                            <MDBCol>
                                <Link to="/howToUseCode">
                                    <div className="fortnite-btn">
                                        <span className="text-uppercase">how to use a code</span>
                                    </div>
                                </Link>
                            </MDBCol>
                            <MDBCol>
                                <Link to="/howToCreateCode">
                                    <div className="fortnite-btn">
                                        <span className="text-uppercase">how to create a code</span>
                                    </div>
                                </Link>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
                />
            </Fragment>
        )
    }
};

export default AllMaps;