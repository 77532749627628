import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import Home from "./pages/Home";
import SingleMap from "./pages/Map";
import AllMaps from "./pages/AllMaps";
import Featured from "./pages/Featured";
import Creator from "./pages/Creator";
import Creators from "./pages/Creators";
import Contact from "./pages/Contact";
import HowUseCode from "./pages/HowUseCode";
import HowToCreateCode from "./pages/HowToCreateCode";
import ErrorPage from "./pages/404";


export default ({ childProps }) => 
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/maps" component={AllMaps} props={childProps} />
    <AppliedRoute path="/map" component={SingleMap} props={childProps} />
    <AppliedRoute path="/featured" exact component={Featured} props={childProps} />
    <AppliedRoute path="/creator" component={Creator} props={childProps} />
    <AppliedRoute path="/contact" component={Contact} props={childProps} />
    <AppliedRoute path="/creators" exact component={Creators} props={childProps} />
    <AppliedRoute path="/howToUseCode" exact component={HowUseCode} props={childProps} />
    <AppliedRoute path="/howToCreateCode" exact component={HowToCreateCode} props={childProps} />
    <Route exact path="/pump-it" render={() => <Redirect to="/map/2201-4498-7425"/>}/>
    <Route exact path="/all-maps" render={() => <Redirect to="/maps"/>}/>
    <Route exact path="/featured-maps" render={() => <Redirect to="/featured"/>}/>
    <Route exact path="/int-snipe-station" render={() => <Redirect to="/map/6752-0807-9594"/>}/>
    <Route exact path="/akes-skill-slide-1" render={() => <Redirect to="/map/1356-8810-7124"/>}/>
    <Route exact path="/free-for-all" render={() => <Redirect to="/maps"/>}/>
    { /* Catch all unmatched routes */ }
    <Route component={ErrorPage} />
  </Switch>
  
  

  

