import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBRow, MDBCol } from "mdbreact";

const deviceWidth = window.innerWidth;
let perPage;
      
      if (deviceWidth < 576) {
        perPage = 2;
      } else {
        perPage = 3;
      }

class Carousel extends React.Component {
    
    renderCarouselItems(items) {

      if (items && items.length > 0) {
        const itemsGroups = items.reduce((all,one,i) => {
            const ch = Math.floor(i/perPage); 
            all[ch] = [].concat((all[ch]||[]),one); 
            return all
        }, []);

        return itemsGroups.map((group, index) =>
        <MDBCarouselItem itemId={index +1} key={index}>
        {group.map((card, index) => <MDBCol size="6" sm={(12 / perPage).toString()} key={index} className="float-left px-1 px-sm-2 h-100">{card}</MDBCol>)}
        </MDBCarouselItem>
        )
     } else {
       return (<MDBCarouselItem itemId="1">
        <MDBCol></MDBCol>
        </MDBCarouselItem>)
     }
      
    }

    render() {
      if (this.props.items && this.props.items.length > 0) {
        return (

            <MDBCarousel activeItem={1} length={this.props.items.length / perPage} slide={true} showControls={true} showIndicators={true} interval={7000} multiItem>
              <MDBCarouselInner>
                <MDBRow>
                  {this.renderCarouselItems(this.props.items)}
                </MDBRow>
              </MDBCarouselInner>
            </MDBCarousel>

        );
      } else {return ""}
       
    }
  
}

export default Carousel;